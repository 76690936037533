import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Button, Icon } from 'components';
import PDFEditorContext from '../context';

const DocumentNavigation = () => {
  const { editor, currentPage, setCurrentPage } = useContext(PDFEditorContext);
  const { totalPages } = editor;

  return totalPages ? (
    <StyledDocumentNavigation className="document-navigation">
      <Button
        simple
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(page => page - 1 || 1)}>
        <Icon name="arrow-simple" />
      </Button>
      {currentPage} / {totalPages}
      <Button
        simple
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage(page => (page + 1 < totalPages ? page + 1 : totalPages))}>
        <Icon name="arrow-simple" />
      </Button>
    </StyledDocumentNavigation>
  ) : null;
};

export default DocumentNavigation;

const StyledDocumentNavigation = styled.div``;
