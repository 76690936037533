/* eslint-disable import/no-named-as-default */
import notify from 'notify';
import React, { useEffect, useReducer, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import useAuth from 'hooks/useAuthContext';
import { tryGetFirstError } from 'utils/requests';
import {
  getPdfDocument,
  getPeopleInTransaction,
  getDocumentHistory,
  getDocumentVariables,
  savePdfDocument,
  shareDocumentForSignatureToAll
} from './api';
import editorReducer, { EditorReducerState, EditorReducerAction } from './editorReducer';
import { DocumentLayer, TransactionMember } from './types.d';

interface PDFEditorContextValues {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPageSize?: { width: number; height: number };
  setCurrentPageSize: React.Dispatch<
    React.SetStateAction<{ width: number; height: number } | undefined>
  >;
  documentId: string;
  editor: EditorReducerState;
  editorDispatch: React.Dispatch<EditorReducerAction>;
  saveDocument: () => Promise<void>;
  transactionMembers: TransactionMember[];
  currentUserName?: string;
  variables?: { key: string; text: string; value: any }[];
  documentIsEditable: boolean;
  setDocumentIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  sendForSignatureToAll: () => Promise<void>;
  history: DocumentLayer[];
  historyQuery: string;
  setHistoryQuery: (
    newValue: string,
    updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn' | undefined
  ) => void;
}

const PDFEditorContext = React.createContext<PDFEditorContextValues>({} as PDFEditorContextValues);

export const PDFEditorContextWrapper = ({ children }) => {
  const [historyQuery, setHistoryQuery] = useQueryParam<string>('history');
  const [documentId] = useQueryParam<string>('id');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<{ width: number; height: number }>();
  const [transactionMembers, setTransactionMembers] = useState<TransactionMember[]>([]);
  const [variables, setVariables] = useState<{ key: string; text: string; value: any }[]>();
  const [history, setHistory] = useState<DocumentLayer[]>([]);
  const [documentIsEditable, setDocumentIsEditable] = useState(true);
  const [editor, editorDispatch] = useReducer(editorReducer, {
    editableNodes: [],
    isAddingNode: false,
    isDeletingNode: false,
    documentLoaded: false,
    changesSaved: true,
    nextDocument: 0,
    buttonText: "Send for signature to all",
    numberOfAddedSignatureOrInitialsFields: 0
  });
  const { profile } = useAuth();

  useEffect(() => {
    setCurrentPage(1);
    if (!documentId) return;
    if (editor.documentLoaded) return;
    (async () => {
      try {
        const { editable, editableNodes, url, filename, next_document } = await getPdfDocument(documentId);
        setDocumentIsEditable(editable);
        editor.nextDocument = next_document;
        if(editor.nextDocument > 0){
          editor.buttonText = "Save and review next";
        }else{
          editor.buttonText = "Send for signature to all";
        }
        console.log("BTN TEXT");
        console.log(editor.buttonText);
        editorDispatch({ type: 'SET_NEXT_DOCUMENT_ID', value: next_document });
        editorDispatch({ type: 'SET_BUTTON_TEXT', value: editor.buttonText });
        editorDispatch({ type: 'SET_DOCUMENT_URL', value: url });
        editorDispatch({ type: 'SET_DOCUMENT_NAME', value: filename });
        editorDispatch({ type: 'SET_NODES', value: editableNodes });
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [documentId, editor.documentLoaded, editor.nextDocument]);

  useEffect(() => {
    if (!documentId) return;
    (async () => {
      try {
        const transactionMembers = await getPeopleInTransaction(documentId);
        setTransactionMembers(transactionMembers);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [documentId]);

  useEffect(() => {
    if (!documentId) return;
    (async () => {
      try {
        const variables = await getDocumentVariables(documentId);
        setVariables(variables);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [documentId]);

  useEffect(() => {
    if (!documentId) return;
    (async () => {
      try {
        const history = await getDocumentHistory(documentId);
        setHistory(history);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [documentId]);

  useEffect(() => {
    if (historyQuery) {
      const layer =
        historyQuery === 'base'
          ? []
          : history.find(item => String(item.id) === historyQuery)?.editableNodes || [];
      editorDispatch({ type: 'SET_NODES', value: layer });
    }
  }, [history, historyQuery]);

  const saveDocument = async () => {
    try {
      const hasInvalidNodes =
        editor.editableNodes.findIndex(
          item => (item.type === 'signature' || item.type === 'initials') && !item.role
        ) !== -1;
      if (hasInvalidNodes) {
        throw Error(
          'You must select role for initials and signature fields or remove them before saving'
        );
      }
      await savePdfDocument(documentId, editor.editableNodes, editor.numberOfAddedSignatureOrInitialsFields);
      editorDispatch({ type: 'SET_CHANGES_SAVED', value: true });
      notify('Document saved');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const sendForSignatureToAll = async () => {
    try {
      await shareDocumentForSignatureToAll(documentId);
      setDocumentIsEditable(false);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const contextValues: PDFEditorContextValues = {
    currentPage,
    currentPageSize,
    documentId,
    editor,
    transactionMembers,
    editorDispatch,
    saveDocument,
    setCurrentPage,
    setCurrentPageSize,
    currentUserName: profile?.name,
    variables,
    documentIsEditable,
    setDocumentIsEditable,
    sendForSignatureToAll,
    history,
    historyQuery,
    setHistoryQuery
  };

  return (
    <PDFEditorContext.Provider value={{ ...contextValues }}>{children}</PDFEditorContext.Provider>
  );
};

export default PDFEditorContext;
