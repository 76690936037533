import React, { useContext, useMemo, useState } from 'react';
import { Button, Modal, ModalProps } from 'components';
import styled from '@emotion/styled';
import { tryGetFirstError } from 'utils/requests';
import { camelToNormal } from 'utils/string';
import notify from 'notify';
import { shareDocuments } from 'api/documents';
import PDFEditorContext from '../context';
import { TransactionMember } from '../types.d';

const ShareDocumentModal: React.FC<Omit<ModalProps, 'children'>> = ({
  open,
  onClose,
  ...props
}) => {
  const { documentId, transactionMembers: members } = useContext(PDFEditorContext);
  const [disableActions, setDisableActions] = useState(false);
  const roleGroups = useMemo(() => {
    const group = {};
    group['Sell Side'] = members.filter(member => member.role?.includes('Seller'));
    group['Buy Side'] = members.filter(member => member.role?.includes('Buyer'));
    // eslint-disable-next-line dot-notation
    group['Vendor'] = members.filter(member => member.role?.includes('Vendor'));
    return group as { [key: string]: TransactionMember[]; };
  }, [members]);

  const handleMemberSelected = async member => {
    setDisableActions(true);
    try {
      await shareDocuments({
        ids: [documentId as any],
        listingMembers: [member.id]
      });
      notify('Document shared');
      onClose?.();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setDisableActions(false);
  };

  return (
    <StyledShareDocumentModal
      className="share-document-modal"
      open={open}
      modalTitle="Share"
      onClose={onClose}
      {...props}>
      {members &&
        Object.entries(roleGroups)
          .filter(([, members]) => (members as WorkspaceMember[]).length > 0)
          .map(([group, members]) => (
            <React.Fragment key={group}>
              <h4 className="users-title">{group}:</h4>
              <div className="users">
                {members.map(member => (
                  <div className="user-item" key={member.email}>
                    <div className="user">
                      <div className="user__info">
                        <span className="user__name">{member.name}</span>
                        <span className="user__role">{camelToNormal(member.role)}</span>
                      </div>
                    </div>
                    <Button
                      secondary
                      disabled={disableActions}
                      onClick={() => handleMemberSelected(member)}>
                      Share
                    </Button>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
      {members?.length === 0 && <p>No members</p>}
    </StyledShareDocumentModal>
  );
};

export default ShareDocumentModal;

const StyledShareDocumentModal = styled(Modal)`
  .users-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 20px;
  }
  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
  }
  .user {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    &__avatar {
      margin: 0 12px 0 0;
      flex-shrink: 0;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__name {
      margin: 0 0 2px;
    }
    &__name,
    &__role {
      display: block;
    }
    &__role {
      color: #8b8b8b;
    }
  }
  .button {
    margin-left: 12px;
  }
`;
