import React from 'react';
import { navigate } from 'gatsby';
import useAuthContext from 'hooks/useAuthContext';

const PrivateRoute = ({ component: Component, children, admin, ...rest }: any) => {
  const { authorized, authData } = useAuthContext();
  if (typeof window === 'undefined') return null;
  if (!authorized) {
    navigate('/login');
    return null;
  }
  if (admin && !authData?.admin) {
    navigate('/');
    return null;
  }
  return <Component {...rest}>{children}</Component>;
};
export default PrivateRoute;
