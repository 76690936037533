import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import NoSSR from 'components/NoSSR';
import PDFEditor from 'views/PDFEditor';

export default () => (
  <NoSSR>
    <Router>
      <PrivateRoute path="/pdf-editor" component={PDFEditor} />
    </Router>
  </NoSSR>
);
