import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';

import { Button, Input } from 'components';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { savePdfDocumentAs } from '../api';
import PDFEditorContext from '../context';

const ShareAndSign = ({ onSuccess }) => {
  const { documentId, editor, editorDispatch, setDocumentIsEditable } = useContext(
    PDFEditorContext
  );
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm();

  const submit = async form => {
    try {
      const hasInvalidNodes =
        editor.editableNodes.findIndex(
          item => (item.type === 'signature' || item.type === 'initials') && !item.role
        ) !== -1;
      if (hasInvalidNodes) {
        throw Error(
          'You must select role for initials and signature fields or remove them before saving'
        );
      }
      const { url, newDocumentId } = await savePdfDocumentAs({
        ...form,
        layer: editor.editableNodes,
        documentId
      });
      notify(`New document "${form.fileName}" saved`);
      editorDispatch({ type: 'SET_DOCUMENT_URL', value: url });
      editorDispatch({ type: 'SET_DOCUMENT_NAME', value: form.fileName });
      navigate(`/pdf-editor?id=${newDocumentId}`);
      setDocumentIsEditable(true);
      onSuccess();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <StyledSaveAs className="save-form" onSubmit={handleSubmit(submit)}>
      <Input
        {...register('fileName', { required: 'Required' })}
        error={errors.filename?.message}
        label="File Name"
        placeholder="Enter File Name"
        className="save-form__input"
      />
      {/* <Controller
        control={control}
        rules={{ required: 'Required' }}
        name="docType"
        defaultValue=""
        render={({ field, formState: { errors } }) => (
          <Dropdown
            options={documentTypeOptions}
            label="Document Type"
            placeholder="Select Document Type"
            error={errors.doctype?.message}
            {...field}
            className="save-form__input"
          />
        )}
      /> */}

      <Button onClick={() => null} disabled={isSubmitting} className="save-form__btn">
        {isSubmitting ? 'Saving...' : 'Store Document'}
      </Button>
    </StyledSaveAs>
  );
};

export default React.memo(ShareAndSign);

const StyledSaveAs = styled.form`
  .save-form {
    display: flex;
    flex-direction: column;
    &__input {
      display: flex;
      width: 100%;
      max-width: 300px;
      margin: 0 0 16px;
      &.dropdown {
        flex-direction: column;
      }
    }
    &__btn {
      width: 100%;
      max-width: 300px;
      display: block;
    }
  }
`;
