/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import ResizeObserver from 'resize-observer-polyfill';
import { useDebouncedCallback } from 'use-debounce';
import Draggable, { DraggableData } from 'react-draggable';
import styled from '@emotion/styled';
import useOutsideClick from 'hooks/useOutsideClick';
import { Dropdown } from 'components';
import PDFEditorContext from '../context';
import { EditableNode } from '../types.d';
import { RoleInitials } from 'enums';
interface EditableNodeComponentProps {
  isNew?: boolean;
  data: EditableNode;
  isDeleting?: boolean;
  onChange?: (node: EditableNode) => void;
  onDelete?: (node: EditableNode) => void;
}

const EditableNodeComponent: React.FC<EditableNodeComponentProps> = ({
  data,
  isDeleting,
  onChange,
  onDelete
}) => {
  const { currentPageSize, currentUserName, variables, editor, editorDispatch } = useContext(PDFEditorContext);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const variableOptions = variables?.map(item => ({ value: item.text, text: item.text }));

  const handleDragStop = (draggableData: DraggableData) => {
    const position = { x: draggableData.x, y: draggableData.y };
    const pagePosition = {
      x: position.x / currentPageSize!.width,
      y: position.y / currentPageSize!.height
    };
    onChange?.({ ...data, position, pagePosition });
  };

  const handleDraggableInputChange = useCallback(
    (e: React.FormEvent<HTMLDivElement>) => {
      const text = e.currentTarget.innerText;
      onChange?.({ ...data, text });
    },
    [data, onChange]
  );

  const onFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClick = () => {
    if (isDeleting) onDelete?.(data);
  };

  const handleRightClick = e => {
    e.preventDefault();
    onDelete?.(data);
    if((data.type == "initials" || data.type == "signature") && data.isNew){
      editorDispatch({ type: 'NUMBER_OF_ADDED_SIGNATURE_OR_INITIALS_FIELDS', value: (--editor.numberOfAddedSignatureOrInitialsFields) });
    }
  };

  const [handleResize] = useDebouncedCallback((entries: ResizeObserverEntry[]) => {
    const width = Number(entries[0].borderBoxSize[0].inlineSize);
    if (width !== data.strikethrougWidth) onChange?.({ ...data, strikethrougWidth: width, strikethrougWidthRelative: width/currentPageSize!.width });
  }, 300);

  useOutsideClick(
    wrapperRef.current,
    () => {
      setIsOpen(false);
    },
    { condition: isOpen }
  );

  useEffect(() => {
    if (data.isNew && ['field', 'text', 'comment'].includes(data.type)) {
      inputRef.current?.focus();
    }
    if((data.type == "initials" || data.type == "signature") && data.isNew){
      editorDispatch({ type: 'NUMBER_OF_ADDED_SIGNATURE_OR_INITIALS_FIELDS', value: (++editor.numberOfAddedSignatureOrInitialsFields) });
    }
  }, [data.type, data.isNew]);

  useEffect(() => {
    if (inputRef.current && ['field', 'text', 'comment'].includes(data.type)) {
      inputRef.current.innerText = data.text;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.type !== 'strikethrough') return;
    const resizeObserver = new ResizeObserver(handleResize);

    if (wrapperRef.current) resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [data.type, handleResize, wrapperRef]);

  const content = useMemo(() => {
    switch (data.type) {
      case 'field':
        return (
          <>
            <div className="handle input-handle" title={data.type} />
            <div
              ref={inputRef}
              className="input"
              role="textbox"
              contentEditable
              defaultValue={data.text}
              onInput={e => handleDraggableInputChange(e)}
              onFocus={onFocus}
            />
            {isOpen && (
              <Dropdown
                value={data.variable}
                placeholder="Variable"
                options={variableOptions}
                onChange={value => {
                  const variableText = variables?.find(item => item.text === value)?.value;
                  if (variableText && inputRef.current) {
                    inputRef.current.innerText = String(variableText);
                  }
                  onChange?.({ ...data, variable: value, text: String(variableText) || data.text });
                }}
                search
              />
            )}
          </>
        );

      case 'text':
      case 'comment':
        return (
          <>
            <div className="handle input-handle" title={data.type} />
            <div
              ref={inputRef}
              className="input"
              role="textbox"
              contentEditable
              defaultValue={data.text}
              onInput={e => handleDraggableInputChange(e)}
              onFocus={onFocus}
            />
          </>
        );
      case 'signature':
      case 'initials':
        return (
          <>
            <div className="handle input-handle" title={data.type} />
            <div className="text">
              {data.type === 'signature' ? `${data.role} ${data.type}` : RoleInitials[data.role!]}
            </div>
          </>
        );
      case 'user_signature':
        return (
          <>
            <div className="handle input-handle" title={data.type} />
            <div
              ref={inputRef}
              className="input"
              role="textbox"
              onInput={e => handleDraggableInputChange(e)}>
              {currentUserName}
            </div>
          </>
        );
      case 'strikethrough':
        return (
          <div className="handle" title={data.type}>
            <hr />
          </div>
        );

      default:
        return null;
    }
  }, [
    currentUserName,
    data,
    handleDraggableInputChange,
    isOpen,
    onChange,
    onFocus,
    variableOptions,
    variables
  ]);

  return (
    <Draggable
      key={data.id}
      defaultPosition={data.position}
      disabled={isDeleting}
      handle=".handle"
      bounds="parent"
      scale={1}
      onStop={(_e, data) => handleDragStop(data)}>
      <StyledNodeComponent
        ref={wrapperRef}
        className={clsx('editable-node', data.type)}
        style={{ width: data.strikethrougWidth }}
        onClick={handleClick}
        onContextMenu={handleRightClick}>
        {content}
      </StyledNodeComponent>
    </Draggable>
  );
};

export default EditableNodeComponent;

const StyledNodeComponent = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  z-index: 900;
  box-sizing: border-box;
  background-color: #ffff003b;

  .handle {
    z-index: 1001;
    width: 10px;
    height: 20px;
    margin-top: -2px;
    margin-left: -12px;
    cursor: move;

    &.input-handle {
      width: 10px;
      background: ${props => props.theme.colors.grayDark};
      opacity: 0.4;
      flex-shrink: 0;
    }
  }

  .input {
    box-sizing: border-box;
    border: 1px dashed ${props => props.theme.colors.seashell};
    min-width: auto;
    line-height: 8px;
    outline: none;
    padding: 0 4px;
    height: 12px;
  }

  .dropdown {
    position: absolute;
    bottom: -45px;
    width: 220px;
  }

  &.text {
    .input {
      font-weight: bold;
    }
  }

  &.signature,
  &.user_signature,
  &.initials {
    .input,
    .text {
      height: 24px;
      font-family: 'Allura', cursive;
      font-size: 24px;
      line-height: 20px;
    }
    .handle {
      height: 24px;
    }
  }

  &.comment {
    .handle {
      background: ${props => props.theme.colors.blue};
    }
  }

  &.strikethrough {
    height: 20px;
    min-width: 30px;
    align-items: center;
    resize: horizontal;
    border: 1px dashed ${props => props.theme.colors.seashell};
    overflow: auto;

    .handle {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 4px;
    }

    hr {
      margin: 0;
      width: 100%;
      border-width: 0 0 2px 0;
      border-color: ${props => props.theme.colors.red};
    }
  }
`;
