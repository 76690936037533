/* eslint-disable dot-notation */
import React, { useContext, useMemo, useState } from 'react';
import { Button, Modal, ModalProps } from 'components';
import styled from '@emotion/styled';
import { tryGetFirstError } from 'utils/requests';
import notify from 'notify';
import PDFEditorContext from '../context';
import { shareDocumentForSignature } from '../api';
import { TransactionMember } from '../types.d';

const ShareSignatureModal: React.FC<Omit<ModalProps, 'children'>> = ({ onClose, ...props }) => {
  const { editor, documentId, transactionMembers: members } = useContext(PDFEditorContext);
  const [disableActions, setDisableActions] = useState(false);
  const roleGroups = useMemo(() => {
    const group = {};

    group['Sell Side'] = members.filter(member => member.role?.includes('Seller'));
    group['Buy Side'] = members.filter(member => member.role?.includes('Buyer'));
    group['Vendor'] = members.filter(member => member.role?.includes('Vendor'));
    return group as { [key: string]: TransactionMember[] };
  }, [members]);

  const signatureRoles = editor.editableNodes
    .filter(node => node.type === 'signature')
    .map(item => item.role);

  const handleMemberSelected = async member => {
    setDisableActions(true);
    try {
      await shareDocumentForSignature(documentId, member.role);
      notify('Signature reminder was sent');
      onClose?.();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setDisableActions(false);
  };

  return (
    <StyledShareDocumentModal
      className="share-document-modal"
      modalTitle="Request Signatures"
      onClose={onClose}
      {...props}>
      {Object.entries(roleGroups)
        .filter(([, members]) => (members as WorkspaceMember[]).length > 0)
        .map(([group, members]) => (
          <React.Fragment key={group}>
            <h4 className="users-title">{group}:</h4>
            <div className="users">
              {members.map(member => (
                <div className="user-item" key={member.email}>
                  <div className="user">
                    <div className="user__info">
                      <span className="user__name">{member.name}</span>
                      <span className="user__role">{member.role}</span>
                    </div>
                  </div>
                  {signatureRoles.includes(member.role) && (
                    <Button
                      secondary
                      disabled={disableActions}
                      onClick={() => handleMemberSelected(member)}>
                      Send Reminder
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
    </StyledShareDocumentModal>
  );
};

export default ShareSignatureModal;

const StyledShareDocumentModal = styled(Modal)`
  .button {
    margin-left: 12px;
  }
  .users-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 20px;
  }
  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
  }

  .user {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    .user__info {
      margin-right: 10px;
    }
    &__avatar {
      margin: 0 12px 0 0;
      flex-shrink: 0;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__name {
      margin: 0 0 2px;
    }
    &__name,
    &__role {
      display: block;
    }
    &__role {
      color: #8b8b8b;
    }
  }
`;
